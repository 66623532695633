import { MainNavItem, SidebarNavItem } from "@/types";

interface DocsConfig {
  mainNav: MainNavItem[];
  sidebarNav: SidebarNavItem[];
}

export const docsConfig: DocsConfig = {
  mainNav: [
    {
      title: "Templates",
      href: "/templates",
    },
    {
      title: "Sections",
      href: "/sections",
    },
    {
      title: "Pricing",
      href: "/pricing",
    },
    // {
    //   title: "Submit Feedback",
    //   href: "https://magicui.featurebase.app/",
    //   external: true,
    // },
    {
      title: "Roadmap",
      href: "https://magicui.featurebase.app/roadmap",
      external: true,
    },
    {
      title: "Discord",
      href: "https://discord.gg/X4BBMBjHNf",
      external: true,
    },
  ],
  sidebarNav: [
    {
      title: "Getting Started",
      items: [
        {
          title: "Introduction",
          href: "/docs",
          items: [],
        },
        {
          title: "Installation",
          href: "/docs/installation",
          items: [],
        },
        // {
        //   title: "Changelog",
        //   href: "/docs/changelog",
        //   items: [],
        // },
        // {
        //   title: "Story",
        //   href: "/docs/story",
        //   items: [],
        // },
      ],
    },
    {
      title: "Templates",
      items: [
        {
          title: "Portfolio",
          href: `/docs/templates/portfolio`,
          items: [],
          label: "New",
        },
        {
          title: "Startup",
          href: `/docs/templates/startup`,
          items: [],
          label: "",
        },
        {
          title: "SaaS",
          href: `/docs/templates/saas`,
          items: [],
          disabled: true,
          label: "Coming soon",
        },
        {
          title: "Mobile App",
          href: `/docs/templates/app`,
          items: [],
          disabled: true,
          label: "Coming soon",
        },
        // {
        //   title: "Desktop App",
        //   href: `/docs/templates/app`,
        //   items: [],
        //   disabled: true,
        //   label: "Coming soon",
        // },
      ],
    },
    {
      title: "Page Sections",
      items: [
        {
          title: "Header",
          href: `/docs/sections/header`,
          items: [],
          label: "New",
        },
        {
          title: "Hero",
          href: `/docs/sections/hero`,
          items: [],
          label: "",
        },
   
        {
          title: "Social Proof Press",
          href: `/docs/sections/social-proof-press`,
          items: [],
        },
        {
          title: "Social Proof Companies",
          href: `/docs/sections/social-proof-companies`,
          items: [],
        },
        {
          title: "Social Proof Testimonials",
          href: `/docs/sections/social-proof-testimonials`,
          items: [],
        },
        {
          title: "Features",
          href: `/docs/sections/features`,
          items: [],
          label: "New",
        },
        {
          title: "Feature Cards",
          href: `/docs/components/feature-cards`,
          items: [],
          label: "",
        },
        {
          title: "Pricing",
          href: `/docs/sections/pricing`,
          items: [],
        },
        {
          title: "Call To Action",
          href: `/docs/sections/call-to-action`,
          items: [],
        },
        {
          title: "FAQ",
          href: `/docs/sections/faq`,
          items: [],
        },
        {
          title: "Footer",
          href: `/docs/sections/footer`,
          items: [],
          label: "",
        },
      ],
    },
    // {
    //   title: "Components",
    //   items: [
    //     {
    //       title: "Bento Grid",
    //       href: `https://magicui.design/docs/components/bento-grid`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Animated List",
    //       href: `https://magicui.design/docs/components/animated-list`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Tweet Card",
    //       href: `https://magicui.design/docs/components/tweet-card`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Marquee",
    //       href: `https://magicui.design/docs/components/marquee`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Globe",
    //       href: `https://magicui.design/docs/components/globe`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Orbiting Circles",
    //       href: `https://magicui.design/docs/components/orbiting-circles`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Dock",
    //       href: `https://magicui.design/docs/components/dock`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Avatar Circles",
    //       href: `https://magicui.design/docs/components/avatar-circles`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Interactive Icon Cloud",
    //       href: `https://magicui.design/docs/components/icon-cloud`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //   ],
    // },
    // {
    //   title: "Special Effects",
    //   items: [
    //     {
    //       title: "Animated Beam",
    //       href: `https://magicui.design/docs/components/animated-beam`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Border Beam",
    //       href: `https://magicui.design/docs/components/border-beam`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Magic Card",
    //       href: `https://magicui.design/docs/components/magic-card`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Meteors",
    //       href: `https://magicui.design/docs/components/meteors`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Rotating Circles",
    //       href: `https://magicui.design/docs/components/rotating-circles`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //   ],
    // },
    // {
    //   title: "Text Animations",
    //   items: [
    //     {
    //       title: "Number Ticker",
    //       href: `https://magicui.design/docs/components/number-ticker`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Animated Shiny Text",
    //       href: `https://magicui.design/docs/components/animated-shiny-text`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Animated Gradient Text",
    //       href: `https://magicui.design/docs/components/animated-gradient-text`,
    //       external: true,
    //       items: [],
    //       label: "",
    //     },
    //     {
    //       title: "Text Reveal",
    //       href: `https://magicui.design/docs/components/text-reveal`,
    //       external: true,
    //       items: [],
    //       label: "",
    //     },
    //     {
    //       title: "Word Rotate",
    //       href: `https://magicui.design/docs/components/word-rotate`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Typing Animation",
    //       href: `https://magicui.design/docs/components/typing-animation`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Wavy Text",
    //       href: `https://magicui.design/docs/components/wavy-text`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Blur In",
    //       href: `https://magicui.design/docs/components/blur-in`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Separate Away",
    //       href: `https://magicui.design/docs/components/separate-away`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Scroll Based Velocity",
    //       href: `https://magicui.design/docs/components/scroll-based-velocity`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Letter Pullup",
    //       href: `https://magicui.design/docs/components/letter-pullup`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Word Pull Up",
    //       href: `https://magicui.design/docs/components/word-pull-up`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Flip Text",
    //       href: `https://magicui.design/docs/components/flip-text`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //   ],
    // },
    // {
    //   title: "Buttons",
    //   items: [
    //     {
    //       title: "Shimmer Button",
    //       href: `https://magicui.design/docs/components/shimmer-button`,
    //       external: true,
    //       items: [],
    //     },
    //   ],
    // },
    // {
    //   title: "Backgrounds",
    //   items: [
    //     {
    //       title: "Animated Grid Pattern",
    //       href: `https://magicui.design/docs/components/animated-grid-pattern`,
    //       external: true,
    //       items: [],
    //       label: "New",
    //     },
    //     {
    //       title: "Retro Grid",
    //       href: `https://magicui.design/docs/components/retro-grid`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Ripple",
    //       href: `https://magicui.design/docs/components/ripple`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Dot Pattern",
    //       href: `https://magicui.design/docs/components/dot-pattern`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Grid Pattern",
    //       href: `https://magicui.design/docs/components/grid-pattern`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Linear Gradient",
    //       href: `https://magicui.design/docs/components/linear-gradient`,
    //       external: true,
    //       items: [],
    //     },
    //     {
    //       title: "Radial Gradient",
    //       href: `https://magicui.design/docs/components/radial-gradient`,
    //       external: true,
    //       items: [],
    //     },
    //   ],
    // },
  ],
};
